import React from 'react';
import { ItemCardHeader } from '@backstage/core-components';
import { Link } from 'react-router-dom'
import { Grid, makeStyles, Card } from '@material-ui/core';

  const useStyles = makeStyles(theme => ({
    grid: {
      gridTemplateColumns: 'repeat(auto-fill, 12em)',
    },
    card: {
      borderRadius: '0px',
    },  
    header: {
      background: 'white',
      color: theme.palette.primary.main,
      "&:hover": {
        color: 'white',
        background: theme.palette.primary.main,
      },
    },
    
  }));

const linkBackground = {
  padding: '5px',
  border: '0',
  color: 'white',
  cursor: 'pointer',
  display: 'inline-block',
  background: "linear-gradient(50deg, #1B99D6 45%, #e65300 40%)",
}


export const QuickLinks2 = () => {
    const classes = useStyles();
    return (
      React.createElement(React.Fragment, null
        , React.createElement(Grid, { item: true, xs: 12, md: 4,}
            , React.createElement(Card, { classes: { root: classes.card },}
                , React.createElement(Link, { to: "/catalog", style: linkBackground,}
                    , React.createElement(ItemCardHeader, { subtitle: "Discover data, apps, platforms and more"     , title: "Explore the Ecosystem"  , classes: { root: classes.header },})
                )
            )
        )
        , React.createElement(Grid, { item: true, xs: 12, md: 4,}
            , React.createElement(Card, { classes: { root: classes.card },}
                , React.createElement(Link, { to: "/manage", style: linkBackground,}
                    , React.createElement(ItemCardHeader, { subtitle: "Take care of the things you own"      , title: "Manage & Mantain"  , classes: { root: classes.header },})
                )
            )
        )
        , React.createElement(Grid, { item: true, xs: 12, md: 4,}
          , React.createElement(Card, { classes: { root: classes.card },}
            , React.createElement(Link, { to: "/docs", style: linkBackground,}
                    , React.createElement(ItemCardHeader, { subtitle: "Find documentation on all OCTO campus services"      , title: "Docs", classes: { root: classes.header },})
                )
            )
        )
    )
  );
};