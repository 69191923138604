import comingsoon from './comingsoon.png';
  import { Page, Header } from '@backstage/core-components';
  import React from 'react';
  
  export const ManagePage = () => {
    return (
      React.createElement(Page, { themeId: "tool",}
        , React.createElement(Header, { title: "Manage",  subtitle: "Take care of the things you own"      ,})
          , React.createElement('img', { src: comingsoon, alt: "", style: { width: '102%' },})
      )
    );
  };