import { HeaderSection } from './HeaderSection'
import { Toolkit } from './Toolkit';

import { HomePageStarredEntities } from '@backstage/plugin-home';
import { Content, Page } from '@backstage/core-components';
import { HomePageSearchBar, SearchContextProvider } from '@backstage/plugin-search';
import React from 'react';
import { QuickLinks2 } from './QuickLinksOption2'
import { Grid, makeStyles } from '@material-ui/core';

const useStyles = makeStyles(theme => ({
  searchBar: {
    display: 'flex',
    maxWidth: '60vw',
    backgroundColor: theme.palette.background.paper,
    boxShadow: theme.shadows[1],
    padding: '8px 0',
    borderRadius: '5px',
    margin: 'auto',
  },
}));
  
export const HomePage = () => {
  const classes = useStyles()
  return (
    React.createElement(SearchContextProvider, null
        , React.createElement(Page, { themeId: "home",}
          , React.createElement(HeaderSection, null)
          , React.createElement(Content, null
            , React.createElement(Grid, { container: true, justifyContent: "center", spacing: 6,}
              , React.createElement(Grid, { container: true, item: true, xs: 12, alignItems: "center", direction: "row",}
                , React.createElement(HomePageSearchBar, {
                  classes: { root: classes.searchBar },
                  placeholder: "Search",}
                )
              )
              , React.createElement(Grid, { container: true, item: true, xs: 12,}
                , React.createElement(Grid, { item: true, xs: 12, md: 6,}
                  , React.createElement(HomePageStarredEntities, { title: "Favorites",})
                )

                , React.createElement(Grid, { item: true, xs: 12, md: 6,}
                  , React.createElement(Toolkit, null)
                )

                , React.createElement(QuickLinks2, null)
              )
            )
          )
        )
    )
  );
};