import { HomePageToolkit } from '@backstage/plugin-home';
import { InfoCard } from '@backstage/core-components';
import { Grid } from '@material-ui/core';
import React from 'react';
import { ComponentAccordion } from '@backstage/plugin-home';
import AppsIcon from '@material-ui/icons/Apps';
import VaultIcon from "../home/icons/VaultIcon";
import SonarIcon from "../home/icons/SonarIcon";
import BackstageIcon from "../home/icons/BackstageIcon";
import {KeycloakIcon} from "../home/icons/KeycloakIcon";
import {GithubIcon} from "../home/icons/GithubIcon";
import {DiscordIcon} from "../home/icons/DiscordIcon";
import {ChaosIcon} from "./icons/ChaosIcon";
import {GrafanaIcon} from "./icons/GrafanaIcon";
import {KubeappsIcon} from "./icons/KubappsIcon";
import {N8nIcon} from "./icons/lowcode/N8nIcon";
import {AirbyteIcon} from "./icons/lowcode/AirbyteIcon";
import {MetabaseIcon} from "./icons/lowcode/MetabaseIcon";
import {HasuraIcon} from "./icons/lowcode/HasuraIcon";
// import { Icon } from '@grafana/ui';

export const Toolkit = () => {
  const ExpandedComponentAccordion = (props) => (
      React.createElement(ComponentAccordion, { expanded: true, ...props,} )
  );
  
  return (
    React.createElement(InfoCard, { title: "Toolkit", noPadding: true,}
      , React.createElement(Grid, { item: true,}
        , React.createElement(HomePageToolkit, {
          title: "General",
          tools: [
            {
              url: 'docs',
              label: 'Getting Started',
              icon: React.createElement(AppsIcon, null ),
            },
              {
                  label: 'Vault',
                  url: 'https://vault.campus.clusterdiali.me',
                  icon: React.createElement(VaultIcon, null),
              },
              {
                  label: 'Sonar',
                  url: 'https://sonar.campus.clusterdiali.me',
                  icon: React.createElement(SonarIcon, null),
              },
              {
                  label: 'Home',
                  url: 'https://home.campus.clusterdiali.me',
                  icon: React.createElement(BackstageIcon, null),
              },
              {
                  label: 'Keycloak',
                  url: 'https://oidc.campus.clusterdiali.me',
                  icon: React.createElement(KeycloakIcon, null),
              },
              {
                  label: 'Github Campus',
                  url: 'https://github.com/octocampus',
                  icon: React.createElement(GithubIcon, null),
              },
              {
                  label: 'Chaos Center',
                  url: 'https://chaos.campus.clusterdiali.me',
                  icon: React.createElement(ChaosIcon, null),
              },
              {
                  label: 'Grafana',
                  url: 'https://grafana.campus.clusterdiali.me',
                  icon: React.createElement(GrafanaIcon, null),
              },
              {
                  label: 'Discord',
                  url: 'https://discord.com/channels/948214033746624584/948214033746624588',
                  icon: React.createElement(DiscordIcon, null),
              },
              {
                  label: 'KubeApps',
                  url: 'https://kubeapps.campus.clusterdiali.me',
                  icon: React.createElement(KubeappsIcon, null),
              },
              {
                  label: 'n8n',
                  url: 'https://n8n.campus.clusterdiali.me',
                  icon: React.createElement(N8nIcon, null),
              },
              {
                  label: 'Airbyte',
                  url: 'https://airbyte.campus.clusterdiali.me',
                  icon: React.createElement(AirbyteIcon, null),
              },
              {
                  label: 'Metabase',
                  url: 'https://metebase.campus.clusterdiali.me',
                  icon: React.createElement(MetabaseIcon, null),
              },
              {
                  label: 'Hasura',
                  url: 'https://hasura.campus.clusterdiali.me',
                  icon: React.createElement(HasuraIcon, null),
              },
          ],
          Renderer: ExpandedComponentAccordion,}
        )
      )
    )
  );
};