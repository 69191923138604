import React from 'react'
import { Icon } from "@material-ui/core";
import GithubLogo from './octo-campus-icon.png'

export const OctoLogo = () => (
    React.createElement(Icon, null
        , React.createElement('img', { src: GithubLogo, alt: "",  height: 40, width: "auto",})
    )
)

export default OctoLogo;