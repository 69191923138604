/*
 * Copyright 2020 The Backstage Authors
 *
 * Licensed under the Apache License, Version 2.0 (the "License");
 * you may not use this file except in compliance with the License.
 * You may obtain a copy of the License at
 *
 *     http://www.apache.org/licenses/LICENSE-2.0
 *
 * Unless required by applicable law or agreed to in writing, software
 * distributed under the License is distributed on an "AS IS" BASIS,
 * WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied.
 * See the License for the specific language governing permissions and
 * limitations under the License.
 */
import React from 'react';
import { makeStyles, createStyles } from '@material-ui/core/styles';
import SvgIcon from '@material-ui/core/SvgIcon';

/** @public */


const useStyles = makeStyles(
    () =>
        createStyles({
            icon: {
                right: '4px',
                pointerEvents: 'none',
                width: '50px',
                height: '50px',
            },
        }),
    { name: 'BackstageClosedDropdown' },
);

const SonarIcon = () => {
    const classes = useStyles();
    return (
        React.createElement(SvgIcon, {
            className: classes.icon,
            fill: "none",
            viewBox: "0 0 512 512"   ,
            xmlns: "http://www.w3.org/2000/svg",}
        
                , React.createElement('title', null, "SonarQube icon" )
                , React.createElement('g', { id: "Illustration_5", 'data-name': "Illustration 5" ,}
                    , React.createElement('path', { className: "cls-1",
                          d: "M408.78,448.09H386.5c0-179.36-148-325.28-329.91-325.28V100.53C250.79,100.53,408.78,256.44,408.78,448.09Z",})
                    , React.createElement('path', { className: "cls-1",
                          d: "M424.18,328.48C397.43,216,306.27,122,192,89.2l5.12-17.84C317.73,106,414,205.23,442.24,324.19Z",})
                    , React.createElement('path', { className: "cls-1",
                          d: "M441.31,222.87c-27.55-60.08-74.49-112.46-132.17-147.51l7.72-12.7c60.19,36.58,109.18,91.27,138,154Z",})
                )
        )
    );
};

export default SonarIcon;